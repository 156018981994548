/* storybook-check-ignore */
import React, { useEffect, useState } from 'react';

import { Box } from '@opendoor/bricks/core';
import { globalObservability } from '@opendoor/observability/slim';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';

import { OdProtosCasaData_Result } from '__generated__/athena';

import { handleAddressSubmit } from 'components/shared/SellerFlowAddressInput';

import useQueryParams from 'helpers/queryParams';

import { useObservability } from '../../../src/helpers/observability';
import { AddressSearchLocation, TrackingTaxonomy } from '../../components/globals';
import { AddressConfirmationModal } from '../../components/landing-pages-v2/AddressConfirmationModal';
import NovoAddressInput, { AutoCompletePrediction } from '../../components/novo/NovoAddressInput';
import {
  ADDRESS_INVALID_RESULT,
  SelectedAddress,
  useAddressValidationContext,
} from '../../helpers/AddressValidationContext';
import {
  ALLOWED_TYPES,
  buildAutocompleteUrl,
  buildPlacesUrl,
  generateGoogleMapsSessionToken,
} from '../../helpers/googleMaps';
import { listUnits, standardizeAddress } from '../api';

const DEBOUNCE_INVOKE_WAIT_MS = 100;
const UNIVERSAL_NIL_ADDRESS = '6aae647b-d603-5533-8464-c42c658b101a';

interface NewSellerFlowAddressInputProps {
  inNewAddressEntryExperiment?: boolean;
  inputLocation: AddressSearchLocation;
  trackingTaxonomy: TrackingTaxonomy;
  analyticsPrefix?: string;
  placeholder: string;
  hideLabel?: boolean;
}

export const gateManualAddressVerification = async ({
  forceRedirect,
  address,
}: {
  forceRedirect: boolean;
  address?: SelectedAddress;
}) => {
  let moreResult: OdProtosCasaData_Result[] = [];
  if (forceRedirect || !address) {
    return { shouldRedirect: true, destination: '/manual-address-entry' };
  }

  let casaAddress = address;
  if (address.source === 'google') {
    const standardizeAddressResponse = await standardizeAddress(address);
    if (
      !standardizeAddressResponse ||
      !standardizeAddressResponse.data ||
      standardizeAddressResponse.errors ||
      standardizeAddressResponse.data?.casa.findByFields?.address?.uuid === UNIVERSAL_NIL_ADDRESS
    ) {
      moreResult = ['ADDRESS_UNKNOWN'];
      return {
        shouldRedirect: true,
        destination: '/manual-address-entry',
        standardizeAddress: address,
        moreResult,
      };
    }
    const addressResult = standardizeAddressResponse.data?.casa.findByFields?.address;
    casaAddress = {
      id: addressResult.id,
      street1: addressResult.street,
      city: addressResult.city,
      state: addressResult.state,
      postal_code: addressResult.postalCode,
      unit: addressResult.unitNumber,
      result: addressResult.result,
    };
  }

  // it's possible for an address to be correct with or without a unit number
  // in these cases, casa won't include the ADDRESS_MISSING_SUB_PREMISE flag, so we have to call
  // a separate endpoint to fetch all possible units for that address.
  if (!casaAddress?.result?.includes('ADDRESS_MISSING_SUB_PREMISE') && casaAddress?.unit === '') {
    if (casaAddress.id && (await doesAddressHaveMultipleUnits(casaAddress.id))) {
      moreResult = ['ADDRESS_MISSING_SUB_PREMISE'];
    }
  }

  if (ADDRESS_INVALID_RESULT.some((error) => casaAddress?.result?.includes(error))) {
    return {
      shouldRedirect: true,
      destination: '/manual-address-entry',
      standardizeAddress: casaAddress,
      moreResult,
    };
  }
  return { shouldRedirect: false, standardizeAddress: casaAddress, moreResult };
};

const doesAddressHaveMultipleUnits = async (addressUuid: string) => {
  const response = await listUnits(addressUuid);
  return response?.addresses?.length >= 2;
};

const NewSellerFlowAddressInput: React.FC<NewSellerFlowAddressInputProps> = ({
  inputLocation,
  trackingTaxonomy,
  hideLabel,
  inNewAddressEntryExperiment,
  placeholder,
}) => {
  const router = useRouter();
  const { trackEvent } = useObservability();

  const [isConfirmationModalOpen, setIsComfirmationModalOpen] = useState(false);
  const [predictions, setPredictions] = useState<AutoCompletePrediction[]>([]);

  const {
    isPredictionSelectLoading,
    validatedAddress,
    setValidatedAddress,
    setAddressResults,
    setIsPredictionSelectLoading,
    setFirstPrediction,
    setPartnerReferralId,
  } = useAddressValidationContext();
  const [searchTerm, setSearchTerm] = useState('');

  const { partner_referral, pa } = useQueryParams();
  const sessionToken = generateGoogleMapsSessionToken();

  useEffect(() => {
    if (partner_referral) {
      setPartnerReferralId(partner_referral as string);
    }
    if (pa) {
      setSearchTerm(pa as string);
      fetchAutocompleteOptions(pa as string);
    }
  }, [partner_referral, pa]);

  const fetchAutocompleteOptions = debounce((input: string) => {
    if (!input) {
      setPredictions([]);
      return;
    }
    fetch(buildAutocompleteUrl(input, sessionToken))
      .then((res) => res.json())
      .then((res) => {
        res.predictions.forEach((result: AutoCompletePrediction) => {
          result.description = result.description.replace(', USA', '');
        });

        const autocompleteOptions = res.predictions.filter((result: AutoCompletePrediction) => {
          result.source = res.source;
          if (res.source === 'google') {
            const terms = result.terms ?? [];
            const resultAllowedTypes = (result.types ?? []).filter((value) =>
              ALLOWED_TYPES.includes(value),
            );
            return (
              (terms.length >= 5 && resultAllowedTypes.length != 0) ||
              (terms.length === 4 && terms[0].value.match(/^\d+\S*\s\S+\s/) !== null)
            );
          }
          return result;
        });
        setPredictions(autocompleteOptions);
        if (autocompleteOptions.length > 0) {
          setFirstPrediction(autocompleteOptions[0]);
        }
      })
      .catch(() => setPredictions([]));
  }, DEBOUNCE_INVOKE_WAIT_MS);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.target.value;

    setSearchTerm(address);
    fetchAutocompleteOptions(address);
    if (address === '') setPredictions([]);
  };

  const addressVerification = async (address: SelectedAddress) => {
    const result = await gateManualAddressVerification({
      forceRedirect: false,
      address,
    });
    const combinedResults = [
      ...(result?.standardizeAddress?.result || []),
      ...(result?.moreResult || []),
    ];
    setAddressResults(combinedResults);
    setValidatedAddress(result?.standardizeAddress);
    if (result.shouldRedirect && result.destination) {
      router.push(result.destination);
    } else {
      setIsComfirmationModalOpen(true);
    }
  };

  const handlePredictionSelect = async (prediction: AutoCompletePrediction) => {
    setSearchTerm(prediction.description);
    setIsPredictionSelectLoading(true);

    const url =
      prediction.source === 'google'
        ? buildPlacesUrl({
            source: 'google',
            placeId: prediction.place_id,
            sessionToken,
          })
        : buildPlacesUrl({
            source: 'casa',
            addressToken: prediction.addressToken,
            sessionToken,
          });

    try {
      const response = await fetch(url);
      const address: SelectedAddress = await response.json();

      if (inNewAddressEntryExperiment) {
        await addressVerification(address);
      } else {
        handleAddressSubmit({
          address,
          product: 'SELL_DIRECT',
          trackEvent,
          trackingTaxonomy,
          inputLocation,
          partnerReferralId: partner_referral as string,
        });
      }
    } catch (e) {
      globalObservability.getSentryClient().captureException?.(e);
    } finally {
      setIsPredictionSelectLoading(false);
    }
  };

  return (
    <Box flex={1}>
      <NovoAddressInput
        inNewAddressEntryExperiment={inNewAddressEntryExperiment}
        id="home-address-input"
        placeholder={placeholder}
        hideLabel={hideLabel}
        disabled={false}
        prediction={{
          predictions: predictions,
          setPredictions: setPredictions,
          searchTerm: searchTerm,
          setSearchTerm: setSearchTerm,
          handlePredictionSelect: handlePredictionSelect,
          onChange: handleAddressChange,
        }}
        product="SELL_DIRECT"
      />
      {isConfirmationModalOpen &&
        inNewAddressEntryExperiment &&
        !isPredictionSelectLoading &&
        validatedAddress && (
          <AddressConfirmationModal
            setIsOpen={setIsComfirmationModalOpen}
            isOpen={isConfirmationModalOpen}
            partnerReferralId={partner_referral as string}
            initialAddress={validatedAddress}
          />
        )}
    </Box>
  );
};

export default NewSellerFlowAddressInput;
